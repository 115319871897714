import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService implements OnDestroy {
  public drop$ = new Subject<{ event: MouseEvent; data: any }>();
  public drag$ = new Subject<{ x: number; y: number }>();

  // tracking if user is dragging file from file system to browser
  public isDraggingFile = false;

  private dragTimer: any;

  constructor() {
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);

    window.addEventListener('dragover', this.onDragOver);
    window.addEventListener('dragleave', this.onDragLeave);
    window.addEventListener('drop', this.onDrop);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('dragover', this.onDragOver);
    window.removeEventListener('dragleave', this.onDragLeave);
    window.removeEventListener('dragleave', this.onDrop);
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDraggingFile = false;
  }

  private onDragOver(event: DragEvent): void {
    event.preventDefault();

    if (!event.dataTransfer.types) {
      return;
    }

    if (event.dataTransfer.types.includes('Files')) {
      this.isDraggingFile = true;
      clearTimeout(this.dragTimer);
    }
  }

  private onDragLeave(): void {
    this.dragTimer = setTimeout(() => {
      this.isDraggingFile = false;
    }, 25);
  }
}
