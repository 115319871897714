import { Directive, ElementRef, HostListener, Input, inject } from '@angular/core';

import { DragAndDropService } from '../services/drag-and-drop.service';

@Directive({
  selector: '[appDraggable]',
  standalone: true,
})
export class DraggableDirective {
  private readonly ref = inject(ElementRef);
  private readonly dndService = inject(DragAndDropService);
  @Input('appDraggable') data: any;
  private lastTouchMove: TouchEvent;

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: MouseEvent | TouchEvent) {
    const anchor = (event.target as HTMLElement).closest('[appDraggableAnchor]');
    if (anchor === null) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    document.body.classList.add('is-dragging-element');

    const htmlStyles = getComputedStyle(document.querySelector('body'));
    const zoom = 1 / (+htmlStyles['zoom'] || 1);

    let pageX;
    let pageY;
    switch (event.type) {
      case 'mousedown':
        const mouseEvent = event as MouseEvent;
        pageX = zoom * mouseEvent.pageX;
        pageY = zoom * mouseEvent.pageY;
        break;
      default:
        const touchEvent = event as TouchEvent;
        pageX = zoom * touchEvent.touches[0].pageX;
        pageY = zoom * touchEvent.touches[0].pageY;
        break;
    }

    const elem = this.ref.nativeElement as HTMLElement;
    const dragEl = elem.cloneNode(true) as HTMLElement;
    const box = elem.getBoundingClientRect();

    const shiftX = pageX - box.left - pageXOffset;
    const shiftY = pageY - box.top - pageYOffset;

    elem.style.opacity = ((+elem.style.opacity ? +elem.style.opacity : 1) / 2).toString();
    dragEl.classList.add('dragged');
    dragEl.style.width = box.width + 'px';
    dragEl.style.height = box.height + 'px';
    dragEl.style.position = 'absolute';
    dragEl.style.opacity = '0.7';
    dragEl.style.zIndex = '1100';
    dragEl.style.left = pageX - shiftX + 'px';
    dragEl.style.top = pageY - shiftY + 'px';
    dragEl.style.margin = '0';

    document.body.appendChild(dragEl);

    this.dndService.drag$.next({ x: pageX, y: pageY });

    dragEl.onmousemove = dragEl.onmouseleave = (e: MouseEvent) => {
      dragEl.style.left = zoom * e.pageX - shiftX + 'px';
      dragEl.style.top = zoom * e.pageY - shiftY + 'px';
      this.dndService.drag$.next({ x: e.pageX, y: e.pageY });
    };

    const onTouchMove = (e: TouchEvent) => {
      if (!e?.touches) {
        return;
      }
      this.lastTouchMove = e;
      dragEl.style.left = zoom * e.touches[0].pageX - shiftX + 'px';
      dragEl.style.top = zoom * e.touches[0].pageY - shiftY + 'px';
      this.dndService.drag$.next({
        x: e.touches[0].pageX,
        y: e.touches[0].pageY,
      });
    };
    document.addEventListener('touchmove', onTouchMove);

    const stopDragging = (e: any) => {
      elem.style.opacity = (+elem.style.opacity * 2).toString();
      dragEl.onmousemove = dragEl.onmouseleave = null;
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchend', stopDragging);
      document.body.removeChild(dragEl);

      if (event.type !== 'mousedown') {
        e.x = e.pageX = this.lastTouchMove.touches[0].pageX;
        e.y = e.pageY = this.lastTouchMove.touches[0].pageY;
      }
      document.body.classList.remove('is-dragging-element');

      // fix for touch devices
      setTimeout(() => {
        this.dndService.drop$.next({ event: e, data: this.data });
      }, 0);
    };

    document.addEventListener('mouseup', stopDragging);
    document.addEventListener('touchend', stopDragging);
  }
}
