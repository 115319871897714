import { Directive, ElementRef, Input, inject, OnInit, OnDestroy } from '@angular/core';
import { type Subscription } from 'rxjs';

import { DragAndDropService } from '../services/drag-and-drop.service';

@Directive({
  selector: '[appDroppable]',
  standalone: true,
})
export class DroppableDirective implements OnInit, OnDestroy {
  private readonly ref = inject(ElementRef);
  private readonly dndService = inject(DragAndDropService);
  @Input('appDroppable') callback: (arg0: any, arg1: MouseEvent) => void;
  private dropSub: Subscription;
  private dragSub: Subscription;

  private ignoreZoom = false;

  public ngOnInit(): void {
    this.ignoreZoom = (this.ref.nativeElement as HTMLElement).hasAttribute('ignoreZoom');

    this.dropSub = this.dndService.drop$.subscribe(({ event, data }) => {
      if (!this.callback) {
        return;
      }
      const htmlStyles = getComputedStyle(document.querySelector('body'));
      const zoom = this.ignoreZoom ? 1 : 1 / (+htmlStyles['zoom'] || 1);

      const elem = this.ref.nativeElement as HTMLElement;
      const box = elem.getBoundingClientRect();
      if (
        zoom * event.pageX > box.left &&
        zoom * event.pageX < box.left + box.width &&
        zoom * event.pageY > box.top &&
        zoom * event.pageY < box.top + box.height
      ) {
        this.callback(data, event);
      }
      this.ref.nativeElement.classList.remove('dragging-over');
    });

    this.dragSub = this.dndService.drag$.subscribe((position) => {
      const htmlStyles = getComputedStyle(document.querySelector('body'));
      const zoom = this.ignoreZoom ? 1 : 1 / (+htmlStyles['zoom'] || 1);

      const elem = this.ref.nativeElement as HTMLElement;
      const box = elem.getBoundingClientRect();
      if (
        zoom * position.x > box.left &&
        zoom * position.x < box.left + box.width &&
        zoom * position.y > box.top &&
        zoom * position.y < box.top + box.height
      ) {
        this.ref.nativeElement.classList.add('dragging-over');
        return;
      }

      if (this.ref.nativeElement.classList.contains('dragging-over')) {
        this.ref.nativeElement.classList.remove('dragging-over');
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.dropSub) {
      this.dropSub.unsubscribe();
    }

    if (this.dragSub) {
      this.dragSub.unsubscribe();
    }
  }
}
